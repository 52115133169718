import { Controller } from "stimulus"
import currency from "currency.js"

function meta(name) {
  return document.head.querySelector(`meta[name=${name}]`).content
}

function formatCurrency(value){
  return value.format({
    symbol: meta("currency_symbol"),
    decimal: meta("currency_decimal_mark"),
    separator: meta("currency_thousands_separator"),
    pattern: meta("currency_format"),
  })
}

export default class extends Controller {
  static targets = [ "subtotal", "shippingCost", "total", "input", "shippingMethod" ]

  connect() {
    this.updateTotal();
  }

  updateTotal() {
    const subtotal = this.inputTargets.reduce((sum, element) => {
      const price = currency(element.dataset.price, { fromCents: true })
      const quantity = currency(element.value)
      return sum.add(price.multiply(quantity))
    }, currency(0))

    const shippingCost = this.calculateShippingCost(subtotal)
    const total = subtotal.add(shippingCost)

    this.subtotalTarget.innerHTML = formatCurrency(subtotal)
    this.shippingCostTarget.innerHTML = formatCurrency(shippingCost)
    this.totalTarget.innerHTML = formatCurrency(total)
  }

  calculateShippingCost(subtotal) {
    const data = this.element.dataset;
    const freeShippingFrom = currency(data.freeShippingFrom, { fromCents: true })

    if (this.shippingMethodTarget.value === "delivery") {
      if (data.freeShippingFrom && subtotal.value >= freeShippingFrom.value) {
        return currency(0)
      } else {
        return currency(data.shippingCost, { fromCents: true })
      }
    } else {
      return currency(0)
    }
  }
}
