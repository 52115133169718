import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = [ "modal", "mainInput", "modalInput", "button" ]
  static values = {
    addLabel: String,
    updateLabel: String
  }

  connect() {
    this.modal = new Modal(this.modalTarget)
  }

  open() {
    this.modalInputTarget.value = this.mainInputTarget.value
    this.modalInputTarget.dispatchEvent(new Event("change"))
    this.updateLabel()
    this.modal.show()
  }

  updateQuantity() {
    this.mainInputTarget.value = this.modalInputTarget.value
    this.mainInputTarget.dispatchEvent(new Event("change"))
    this.modal.hide()
  }

  updateLabel() {
    if (Number(this.mainInputTarget.value) > 0) {
      this.buttonTarget.innerHTML = this.updateLabelValue
    } else {
      this.buttonTarget.innerHTML = this.addLabelValue
    }
  }
}
