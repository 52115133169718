import { Controller } from "stimulus"

function hide(element) {
  element.classList.add("hide")
}

function show(element) {
  element.classList.remove("hide")
}

export default class extends Controller {
  static targets = [ "input", "delivery", "summary", "pickupLocation" ]

  connect() {
    this.inputTarget.addEventListener("change", () => this.showShippingMethods())
    this.showShippingMethods()
  }

  showShippingMethods() {
    const value = this.inputTarget.value

    if (value === "pickup_location") {
      show(this.pickupLocationTarget);
      hide(this.deliveryTarget);
      this.summaryTargets.forEach(hide);
    }
    else if (value === "delivery") {
      show(this.deliveryTarget);
      hide(this.pickupLocationTarget);
      this.summaryTargets.forEach(show);
    } else {
      hide(this.deliveryTarget);
      hide(this.pickupLocationTarget);
      this.summaryTargets.forEach(hide);
    }
  }
}
