import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "decrement", "increment", "root"  ]

  connect() {
    this.updateButtonsState()
  }

  increment(event) {
    this.inputTarget.stepUp()
    this.inputTarget.dispatchEvent(new Event("change"))
    event.stopPropagation()
  }

  decrement(event) {
    this.inputTarget.stepDown()
    this.inputTarget.dispatchEvent(new Event("change"))
    event.stopPropagation()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  updateButtonsState() {
    const { min, max, value } = this.inputTarget

    this.updateSelectedClass(Number(value) > 0)
    this.updateButtonState(Number(value) <= Number(min), this.decrementTarget)
    this.updateButtonState(Number(value) >= Number(max), this.incrementTarget)
  }

  updateSelectedClass(toggle) {
    if(toggle) {
      this.rootTarget.classList.add("is-selected")
    } else {
      this.rootTarget.classList.remove("is-selected")
    }
  }

  updateButtonState(condition, element) {
    if (condition) {
      element.classList.add("disabled")
    } else {
      element.classList.remove("disabled")
    }
  }
}
