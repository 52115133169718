import variables from "../stylesheets/variables.scss"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "scroll" ]
  static values = {
    perform: Boolean
  }

  connect() {
    if (this.performValue) {
      this.scrollTarget.scrollIntoView()
    }
  }
}
